import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QueryService } from 'src/app/services/query.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.css']
})
export class AttachmentPreviewComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<AttachmentPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public image,
    private queryService: QueryService,
    public sharedService: SharedService, ) { }

  ngOnInit(): void {
  }
  onClose(action): void {
    this.dialogRef.close(action);
  }

}
